// extracted by mini-css-extract-plugin
export var Container = "NavbarProjects-module--Container--79sGI";
export var DropdownImageWrapper = "NavbarProjects-module--DropdownImageWrapper--atoGS";
export var DropdownMenu = "NavbarProjects-module--DropdownMenu--n3773";
export var FlagImageWrapper = "NavbarProjects-module--FlagImageWrapper--3M9B5";
export var HistoryButton = "NavbarProjects-module--HistoryButton--hksql";
export var HistoryContent = "NavbarProjects-module--HistoryContent--GxulN";
export var HistoryDropdown = "NavbarProjects-module--HistoryDropdown--o6Dhl";
export var NavbarProjectsWrapper = "NavbarProjects-module--NavbarProjectsWrapper--uJRsT";
export var SecondBar = "NavbarProjects-module--SecondBar--Y3hES";
export var TitleWrapper = "NavbarProjects-module--TitleWrapper--+iBKG";
export var WhiteTextLink = "NavbarProjects-module--WhiteTextLink--NPtfO";
export var dropbtn = "NavbarProjects-module--dropbtn--pfW53";
export var dropdown = "NavbarProjects-module--dropdown--UNJuz";
export var dropdownContent = "NavbarProjects-module--dropdownContent--9+OUA";
import React, { useState } from "react";
import {
  NavbarProjectsWrapper,
  TitleWrapper,
  WhiteTextLink,
  Container,
  DropdownImageWrapper,
  FlagImageWrapper,
  dropdown,
  dropbtn,
  dropdownContent,
  DropdownMenu,
  SecondBar,
  HistoryContent
} from "./NavbarProjects.module.css";
import { string, number } from "prop-types";
import BurgerMenu from "../../../BurgerMenuProjects";
import Dropdown from "./../../../../res/caret-down.png";
import FrenchFlag from "./../../../../res/france.png";
import EnglishFlag from "./../../../../res/united-kingdom.png";

const NavbarProjects = ({ width, path }) => {
  const [displayBurger, setDisplayBurger] = useState(false);
  const [navB , setnavB] = useState(false);

  // Ligne a changé pour récuperer les bons liens
  // const base_url = "http://localhost:8000/"

  const currentURL =
    typeof window !== "undefined" ? window.location.href : null;
    var englishUrl = "/en/#top";
    var frenchUrl = "/fr/#top";

  console.log(currentURL);


  return (

    <div>
    <div className={NavbarProjectsWrapper}>
      <div style={{display: "flex", alignItems: "flex-end"}}>
        <a className={WhiteTextLink} href="/fr-projects/#top">
          <h2 className={TitleWrapper}>GHOST PROJECT
          </h2>
        </a>
        {/* <p style={{color: "white", letterSpacing: "0px", fontSize: "14px", lineHeight: "40px"}}>by ghost galerie</p> */}
      </div>
        <div className={Container}>
          <a href="/fr/">Ghost galerie</a>
          <a href="/fr-projects/artistes">Artistes</a>
          <a href="/fr-projects/expositions">Expositions</a>
          <a href="/fr-projects/a-propos">À propos</a>
          <a href="/fr/contactez-nous">Contact</a>
          {/* <a href="/fr/shop">Shop</a> */}
          <div className={dropdown}>
            <button className={dropbtn}>
              {/* Langue */}
              {/* <img className={DropdownImageWrapper} src={Dropdown} alt=""/> */}
              <img className={FlagImageWrapper} src={EnglishFlag} alt=""/>
            </button>
            <div className={dropdownContent}>
              <a href={frenchUrl}>
                <img className={FlagImageWrapper} src={FrenchFlag} alt=""/>
                Français
              </a>
              <a href={englishUrl}>
                <img className={FlagImageWrapper} src={EnglishFlag} alt=""/>
                English    
              </a>
            </div>
          </div>
        </div>
        <BurgerMenu
          onClick={() => setDisplayBurger(!displayBurger)}
          display={displayBurger}
          setDisplay={setDisplayBurger}
        >
          <a href="/fr/">
            Ghost galerie
          </a>
          <a href="/fr/artistes">
            Artistes
          </a>
          {/* <a href="/fr/visite-virtuelle">
            Visite virtuelle
          </a> */}
          <a href="/fr-projects/expositions">Expositions</a>

          <a href="/fr-projects/a-propos">
            À propos
          </a>
          <a href="/fr-projects/">
            Ghost project
          </a>
          <a href="/fr/contactez-nous">
            Contact
          </a>
          <div className={dropdown}>
            <button className={dropbtn}>
              {/* Langue */}
              {/* <img className={DropdownImageWrapper} src={Dropdown} alt=""/> */}
              <img className={FlagImageWrapper} src={EnglishFlag} alt=""/>
            </button>
            <div className={dropdownContent}>
              <a href={frenchUrl}>
                <img className={FlagImageWrapper} src={FrenchFlag} alt=""/>
                Français
              </a>
              <a href={englishUrl}>
                <img className={FlagImageWrapper} src={EnglishFlag} alt=""/>
                English    
              </a>
            </div>
          </div>
          {/* <a href="/fr/shop">
            Shop
          </a> */}
        </BurgerMenu>
    </div>
    </div>
  );
};

NavbarProjects.propTypes = {
  width: number.isRequired,
};

NavbarProjects.defaultProps = {};

export default NavbarProjects;

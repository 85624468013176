import React from "react";
import {
  ButtonWrapper,
  BurgerIcon,
  BurgerDropDown,
  CrossIcon,
  Burger
} from "./BurgerMenu.module.css";
import { bool, func } from "prop-types";
import { classNames } from "../../helpers/classNames";

const BurgerMenu = ({ display, setDisplay, children, ...props }) => {
  return (
    <div className={Burger}>
      <button type="button" {...props} className={ButtonWrapper}>
        <div className={classNames([BurgerIcon, display && CrossIcon])} />
      </button>
      {display && <div className={BurgerDropDown}>{children}</div>}
    </div>
  );
};

BurgerMenu.propTypes = {
  display: bool.isRequired,
  setDisplay: func.isRequired,
};

export default BurgerMenu;

// extracted by mini-css-extract-plugin
export var Addresses = "Footer-module--Addresses--tzI7x";
export var AideWrapper = "Footer-module--AideWrapper--vUCKL";
export var CopyrightWrapper = "Footer-module--CopyrightWrapper--o5nm+";
export var FollowWrap = "Footer-module--FollowWrap--iJkIE";
export var FooterResp = "Footer-module--FooterResp--dfzUp";
export var FooterWrapper = "Footer-module--FooterWrapper--0n35t";
export var FourthResp = "Footer-module--FourthResp--YkEWQ";
export var HorairesTextWrapper = "Footer-module--HorairesTextWrapper--HNhSN";
export var HorairesWrapper = "Footer-module--HorairesWrapper--4-ZPS";
export var Icon = "Footer-module--Icon--4c44l";
export var LineWrapper = "Footer-module--LineWrapper--r1HZ3";
export var LinkWrapper = "Footer-module--LinkWrapper--HwikT";
export var LogoLinkWrapper = "Footer-module--LogoLinkWrapper--n6ivJ";
export var LogoWrapper = "Footer-module--LogoWrapper--G2hLD";
export var MuseumInfosWrapper = "Footer-module--MuseumInfosWrapper--xnJN5";
export var NetworksLinkWrapper = "Footer-module--NetworksLinkWrapper--GJ+WY";
export var NetworksWrapper = "Footer-module--NetworksWrapper--p6-+E";
export var PaddingMobile = "Footer-module--PaddingMobile--vzpW4";
export var Spacer = "Footer-module--Spacer--Q+5L9";
export var ThirdResp = "Footer-module--ThirdResp--0LNyj";
export var Wrapper = "Footer-module--Wrapper--OLPTK";
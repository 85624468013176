import * as React from "react";
import {
  Wrapper,
  AboutUsWrapper,
} from "./APropos.module.css";
import { GrayTitle } from "./../../../css/Title.module.css";

const APropos = () => {
  return (
    <div className={Wrapper}>
      <h1 className={GrayTitle}>À PROPOS</h1>
      <div className={AboutUsWrapper}>
      <br />
        <br />
        <br />
        <p style={{fontSize:"16px"}}>Nouveau chapitre de notre histoire, Ghost project souhaite mettre en lumière la scène artistique émergente contemporaine. Un projet tourné vers le futur et vers la mémoire de demain.</p>
        <br />
        <p style={{fontSize:"16px"}}>
          Les artistes émergents forment la matière de notre avenir.</p>
        <br />
        <p style={{fontSize:"16px"}}>
          Par cette programmation, nous souhaitons accompagner la jeune création qui participe à l'écriture de l’histoire des générations futures.
          </p>
       <br />
      </div>
    </div>
  );
};

export default APropos;
